import Img from "gatsby-image";
import React from "react";

function PartnerCard(props) {
  let { title, image, description, isFirst = false } = props;
  return (
    <div
      className={`flex w-full xl:max-w-sm  flex-col shadow-lg px-6 py-4  mt-4 xl:mt-0 ${
        isFirst === false ? "xl:ml-4" : ""
      } `}
    >
      <div className="flex content-center justify-center">
        <Img fixed={image} />
      </div>
      <h3 className="font-semibold text-xl mb-2 font-display mt-4">{title}</h3>
      <p className="text-base mb-2 font-light  ">{description}</p>
    </div>
  );
}

export default PartnerCard;
