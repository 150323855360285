import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { HTMLContent } from "../components/Content";
import Layout from "../components/layout";
import PartnerCard from "../components/PartnerCard";
import SEO from "../components/seo";

export const PartnersPageTemplate = ({ post }) => {
  return (
    <div className="flex flex-col flex-wrap mt-10">
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
        {post.frontmatter.partners.map((partner) => {
          if (partner.logo) {
            return (
              <PartnerCard
                key={partner.title}
                title={partner.title}
                description={partner.description}
                image={partner.logo.childImageSharp.fixed}
                isFirst={true}
              />
            );
          } else {
            return undefined;
          }
        })}
      </div>
    </div>
  );
};

PartnersPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const PartnersPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <>
      <SEO
        title={post.frontmatter.seo.meta_title}
        description={post.frontmatter.seo.meta_description}
        pathname={post.frontmatter.path}
      />
      <Layout
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        heroBackgroundImage={post.frontmatter.hero_background_image}
      >
        <main className="container mx-auto px-6 py-10  max-w-7xl ">
          <PartnersPageTemplate
            contentComponent={HTMLContent}
            content={post.html}
            post={post}
          />
        </main>
      </Layout>
    </>
  );
};

PartnersPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PartnersPage;

export const partnersPageQuery = graphql`
  query PartnersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        partners {
          description
          title
          logo {
            childImageSharp {
              fixed(height: 57) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
        hero_background_image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        seo {
          meta_title
          meta_description
        }
        subtitle
      }
    }
  }
`;
